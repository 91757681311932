export default class Select {
    constructor(selectClassName) {
        this.getNeededElements(selectClassName);
        if (!this.neededElementsExist()) return;
        this.events();
        this.checkForSelected();
    }

    /**
     * Tries to get all elements from the dom and saves them to this
     * object
     */
    getNeededElements = (selectClassName) => {
        this.select = document.querySelector(selectClassName);
        if (this.select == null) return;

        this.selectInput = this.select.querySelector(".select__input");
        this.selectSelected = this.select.querySelector(".select__selected");
        this.selectSelectedText = this.select.querySelector(
            ".select__selected-text"
        );
        this.selectSelectedIcon = this.select.querySelector(
            ".select__selected-icon"
        );
        this.selectDropdown = this.select.querySelector(".select__dropdown");
        this.selectDropdownItems = this.select.querySelectorAll(
            ".select__dropdown-list li"
        );
    };

    /**
     * Returns true if all needed elments could be found otherwise false
     *
     * @return {boolean} true if all elements are found otherwise false
     */
    neededElementsExist = () => {
        return (
            this.select != null &&
            this.selectInput != null &&
            this.selectSelected != null &&
            this.selectSelectedText != null &&
            this.selectSelectedIcon != null &&
            this.selectDropdown != null &&
            this.selectDropdownItems != null
        );
    };

    /**
     * Register all event handlers
     */
    events = () => {
        this.selectSelected.addEventListener("click", this.handleSelectClick);
        this.selectDropdownItems.forEach((item) =>
            item.addEventListener("click", this.handleDropdownItemClick)
        );
    };

    /**
     * Checks if an item is already selected and sets
     * the input field and selected text element
     */
    checkForSelected = () => {
        this.selectDropdownItems.forEach((item) => {
            if (item.hasAttribute("data-selected")) {
                this.selectSelectedText.textContent = item.textContent;
                this.selectInput.value = item.dataset.value;
            }
        });
    };

    /**
     * This method handles the logic if the user clicks on the select
     */
    handleSelectClick = () => {
        if (this.isDropdownActive()) {
            this.closeDropdown();
        } else {
            this.openDropdown();
        }
    };

    /**
     * Checks if the dropdown is visible and returns true, otherwise false
     *
     * @returns true if the dropdown is visible otherwise false
     */
    isDropdownActive = () => {
        return this.selectDropdown.classList.contains(
            "select__dropdown--active"
        );
    };

    /**
     * Closes the dropdown
     */
    closeDropdown = () => {
        this.selectSelectedIcon.classList.remove(
            "select__selected-icon--rotated"
        );
        this.selectDropdown.classList.remove("select__dropdown--active");
    };

    /**
     * Opens the dropdown
     */
    openDropdown = () => {
        this.selectSelectedIcon.classList.add("select__selected-icon--rotated");
        this.selectDropdown.classList.add("select__dropdown--active");
    };

    /**
     * Handles the click of a user on a select item
     * Copies the value of the clicked item into the hidden input field
     * Puts the text of the clicked item into the selectSelectedText element
     */
    handleDropdownItemClick = (e) => {
        let selectedItem = e.target;
        this.selectSelectedText.textContent = selectedItem.textContent;
        this.selectInput.value = selectedItem.dataset.value;
        this.closeDropdown();
        this.selectInput.dispatchEvent(new Event("change"));
    };
}
