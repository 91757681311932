export default class Ventilation {
    constructor() {
        this.getNeededElements();
        if (!this.neededElementsExist()) return;
        this.addObserver();
        this.addVentilationScrollRotation();
    }

    /**
     * Tries to get all elements from the dom and saves them to this
     * object
     */
    getNeededElements = () => {
        this.ventilationImg = document.querySelector(
            ".ventilation-section__img"
        );
    };

    /**
     * Returns true if all needed elments could be found otherwise false
     *
     * @return {true|false} true if all elements are found otherwise false
     */
    neededElementsExist = () => {
        return this.ventilationImg != null;
    };

    addVentilationScrollRotation = () => {
        window.addEventListener("scroll", () => {
            const desktopMediaQuery = window.matchMedia("(min-width: 1280px)");
            const beforeTabletMediaQuery =
                window.matchMedia("(min-width: 650px)");

            // Desktop
            if (desktopMediaQuery.matches) {
                this.ventilationImg.style.transform = "translateY(0)";
            } else if (beforeTabletMediaQuery.matches) {
                this.ventilationImg.style.transform = "translateY(-50%)";
            } else {
                this.ventilationImg.style.transform = "translateX(-50%)";
            }

            this.ventilationImg.style.transform +=
                "rotate(-" + window.pageYOffset / 10 + "deg)";
        });
    };

    /**
     * Initialize Intersection Observer
     */
    addObserver = () => {
        const observerConfig = { treshold: 1 };
        this.observer = new IntersectionObserver(
            this.observerCallback,
            observerConfig
        );
        this.observer.observe(this.ventilationImg);
    };

    /**
     * Intersection Observer Callback Function
     *
     * @param {IntersectionObserverEntry[]} entries
     */
    observerCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.triggerAnimation(entry.target);
                this.observer.unobserve(entry.target);
            }
        });
    };

    /**
     *
     * @param {HtmlElement} element
     */
    triggerAnimation = (element) => {
        if (this.didAnimateAlready) return;
        element.classList.add("ventilation-section__img--visible");
    };
}
