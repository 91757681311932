export default class Header {
    constructor() {
        this.getNeededElements();
        if (!this.neededElementsExist()) return;
        this.events();
    }

    /**
     * Tries to get all elements from the dom and saves them to this
     * object
     */
    getNeededElements = () => {
        this.headerMobileMenuOpen = document.querySelector(
            ".header__mobile-menu-open"
        );

        this.headerMobileMenuClose =
            document.querySelector(".mobile-menu-close");

        this.headerMobileNavWrapper = document.querySelector(
            ".mobile-nav-wrapper"
        );

        this.headerMobileNavLinks =
            document.querySelectorAll(".mobile-nav-link");

        this.headerNavLinks = document.querySelectorAll(".header__nav-link");

        this.videoBackgroundPlayer = document.querySelector(
            ".header__video-background"
        );

        this.imageBackground = document.querySelector(
            ".header__image-background"
        );
    };

    /**
     * Returns true if all needed elments could be found otherwise false
     *
     * @return {boolean} true if all elements are found otherwise false
     */
    neededElementsExist = () => {
        return (
            this.headerMobileMenuOpen != null &&
            this.headerMobileMenuClose != null &&
            this.headerMobileNavWrapper != null &&
            this.headerMobileNavLinks.length > 0 &&
            this.headerNavLinks.length > 0 &&
            this.videoBackgroundPlayer != null &&
            this.imageBackground != null
        );
    };

    /**
     * Creates all needed Event Handlers
     */
    events = () => {
        this.headerMobileMenuOpen.addEventListener("click", this.openMenu);
        this.headerMobileMenuClose.addEventListener("click", this.closeMenu);

        // Register an event handler to every link to close the menu if a link got clicked
        this.headerMobileNavLinks.forEach((link) =>
            link.addEventListener("click", this.closeMenu)
        );

        window.addEventListener("resize", this.fixHeaderNavLinksSize);

        window.addEventListener("DOMContentLoaded", this.fixHeaderNavLinksSize);

        window.addEventListener("DOMContentLoaded", this.playBackgroundVideo);
    };

    /**
     * Because of the hover effect which changes the font style
     * from normal to italic, the links are going to jump to much
     * By fixing them, they will not jump.
     * The size should only be fixed if its a desktop viewport.
     */
    fixHeaderNavLinksSize = () => {
        const mediaQuery = window.matchMedia("(min-width: 1280px)");
        if (mediaQuery.matches) {
            this.headerNavLinks.forEach((link) => {
                link.style.width = link.offsetWidth + "px";
            });
        }
    };

    /**
     * Opens the mobile menu
     */
    openMenu = () => {
        this.headerMobileNavWrapper.classList.add(
            "mobile-nav-wrapper--visible"
        );
    };

    /**
     * Closes the mobile menu
     */
    closeMenu = () => {
        this.headerMobileNavWrapper.classList.remove(
            "mobile-nav-wrapper--visible"
        );
    };

    /**
     * Starts the background video in the header
     * If its not possible to start the video
     * show the background-image
     */
    playBackgroundVideo = () => {
        this.videoBackgroundPlayer
            .play()
            .then(() => {})
            .catch((err) => {
                // Show picture instead of video
                // It is possible that this user uses
                // an IPhone with low power mode activated
                if (err.name === "NotAllowedError") {
                    this.imageBackground.classList.add(
                        "header__image-background--visible"
                    );
                    this.videoBackgroundPlayer.classList.add(
                        "header__video-background--hidden"
                    );
                }
            });
    };
}
