import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default class Support {
    constructor() {
        this.initServicesSwiper();
    }

    initServicesSwiper = () => {
        this.servicesSwiper = new Swiper(".support-section__services-swiper", {
            direction: "horizontal",
            loop: false,

            navigation: {
                nextEl: ".support-section__swiper-button-next",
                prevEl: ".support-section__swiper-button-prev",
            },

            breakpoints: {
                1280: {
                    slidesPerView: 2,
                    spaceBetween: 126,
                },
            },
        });
    };
}
