import Swiper from "swiper/bundle";
import "swiper/css/bundle";

/**
 * This classes purpose is to handle
 * the slides in the radiator section.
 * The illustration slide on the top
 * and the illustration slide on the bottom
 *
 * If an illustration on the top gets clicked,
 * this class will recognize it and trigger the
 * heating type swiper (bottom one) to change it's slide accordingly.
 *
 * Vice versa, if the bottom heating type swiper changes
 * his active slide then it changes the slide of the illustration
 * slider
 *
 * This class also handles the animations when a slide changes.
 * It adds the proper classes to the slides and removes them
 * after a slide changed, to trigger an animation through css.
 */
export default class Radiator {
    constructor() {
        this.getNeededElements();
        if (!this.neededElementsExist()) return;

        this.initIllustrationSwiper();
        this.registerIllustrationSwiperEventHandler();

        this.initHeatingTypesSwiper();
        this.registerHeatingTypeSwiperEventHandler();
    }

    /**
     * Tries to get all elements from the dom and saves them to this
     * object
     */
    getNeededElements = () => {
        this.slides = document.querySelectorAll(
            ".radiator-section__illustrations-swiper .swiper-slide"
        );
        this.activeSlide = document.querySelector(
            ".radiator-section .swiper-slide--active"
        );
    };

    /**
     * Returns true if all needed elments could be found otherwise false
     *
     * @return {boolean} true if all elements are found otherwise false
     */
    neededElementsExist = () => {
        return (
            this.slides != null &&
            this.slides.length > 0 &&
            this.activeSlide != null
        );
    };

    /**
     * Initialise the heating type swiper
     */
    registerIllustrationSwiperEventHandler = () => {
        this.slides.forEach((slide, index) => {
            slide.addEventListener("click", () =>
                this.handleIllustrationSlideClick(index)
            );
        });
    };

    /**
     * Initialise the heating type swiper
     */
    handleIllustrationSlideClick = (slideIndex) => {
        this.setIllustrationActiveSlide(slideIndex);
        this.heatingTypeSwiper.slideTo(slideIndex);
    };

    /**
     * Initialise the illustration swiper
     */
    initIllustrationSwiper = () => {
        this.illustrationsSwiper = new Swiper(
            ".radiator-section__illustrations-swiper",
            {
                direction: "horizontal",
                loop: false,
                slidesPerView: 3,

                navigation: {
                    nextEl: ".radiator-section__swiper-button-next",
                    prevEl: ".radiator-section__swiper-button-prev",
                },

                breakpoints: {
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 5,
                    },
                    824: {
                        slidesPerView: 5,
                        slidesPerView: "auto",
                    },
                },
            }
        );
    };

    /**
     * Initialise the heating type swiper
     */
    initHeatingTypesSwiper = () => {
        this.heatingTypeSwiper = new Swiper(
            ".radiator-section__heating-types-swiper",
            {
                direction: "horizontal",
                loop: false,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            }
        );
        this.heatingTypeSwiperSetDirectionClasses();
    };

    /**
     * This method loops over the slides of the
     * heatingTypeSwiper and checks there index.
     * If the index of a slide is smaller than the current index
     * the slide gets a class attached that marks this slide
     * that its left to the current slide.
     *
     * The same for slides their index is higher
     *  than the of the current slide
     */
    heatingTypeSwiperSetDirectionClasses = () => {
        let currentIndex = this.heatingTypeSwiper.activeIndex;
        this.heatingTypeSwiper.slides.forEach((slide, index) => {
            if (index < currentIndex) {
                slide.classList.add("swiper-slide--left");
            } else if (index > currentIndex) {
                slide.classList.add("swiper-slide--right");
            }
        });
    };

    /**
     * Sets event handler for different events
     * which get triggered by the heating type swiper
     */
    registerHeatingTypeSwiperEventHandler = () => {
        this.heatingTypeSwiper.on("activeIndexChange", () =>
            this.setIllustrationSelection(this.heatingTypeSwiper.activeIndex)
        );
        this.heatingTypeSwiper.on("realIndexChange", () => {
            let currentIndex = this.heatingTypeSwiper.activeIndex;
            let activeSlide = this.heatingTypeSwiper.slides[currentIndex];
            activeSlide.classList.remove("swiper-slide--left");
            activeSlide.classList.remove("swiper-slide--right");
            this.heatingTypeSwiperSetDirectionClasses();
        });
    };

    /**
     * Sets the illustration swiper active slide
     *
     * @param {number} toIndex
     */
    setIllustrationSelection = (toIndex) => {
        this.setIllustrationActiveSlide(toIndex);
        this.illustrationsSwiper.slideTo(toIndex);
    };

    /**
     * Marks the current selected illustration
     * the illustration gets red
     *
     * @param {number} toIndex
     */
    setIllustrationActiveSlide = (toIndex) => {
        this.activeSlide.classList.remove("swiper-slide--active");
        this.activeSlide = this.slides[toIndex];
        this.activeSlide.classList.add("swiper-slide--active");
    };
}
