export default class History {
    constructor() {
        this.getNeededElements();
        if (!this.neededElementsExist()) return;
        this.events();
    }

    /**
     * Get all needed HTML Elements
     *
     */
    getNeededElements = () => {
        this.line = document.querySelector(".history-section__line");
        this.content = document.querySelector(".history-section__content");
        this.pointer = document.querySelector(".history-section__pointer");
        this.firstHistoryText = document.querySelector(
            ".history-section__history-text-first"
        );
        this.historyTitleWrappers = document.querySelectorAll(
            ".history-section__history-title-wrapper p"
        );
    };

    /**
     * Check if all needed html elements could be found
     *
     * @returns boolean
     */
    neededElementsExist = () => {
        return this.pointer != null && this.historyTitleWrappers != null;
    };

    /**
     * Register Event Handler
     *
     */
    events = () => {
        // Wait until the whole document is loaded, then again wait some milliseconds so
        // all css is applied to all elements
        window.addEventListener("DOMContentLoaded", () => {
            setTimeout(() => {
                this.initData();
                this.setPointerStartPosition();
                this.handlePointerPosition();
                window.addEventListener("scroll", this.handlePointerPosition);
            }, 50);
        });

        // If the window resizes, refresh the data
        window.addEventListener("resize", this.initData);
    };

    /**
     * Get the bounding rect of each element and store it as a property
     * for later usage and set the pointer to visible by setting style.display to block
     */
    initData = () => {
        this.pointer.style.display = "block";
        this.bodyRect = document.body.getBoundingClientRect();
        this.historyTitleWrappersRects = Array.from(
            this.historyTitleWrappers
        ).map((wrapper) => {
            return wrapper.getBoundingClientRect();
        });
        this.pointerRect = this.pointer.getBoundingClientRect();
        this.contentRect = this.content.getBoundingClientRect();
        this.firstHistoryTextRect =
            this.firstHistoryText.getBoundingClientRect();
    };

    /**
     * Returns a computed property of a element as a number
     *
     * @param {HTMLElement} element
     * @param {string} propertyName
     * @returns
     */
    getComputedPixel = (element, propertyName) => {
        return parseFloat(
            window
                .getComputedStyle(element, null)
                .getPropertyValue(propertyName)
        );
    };

    /**
     * Set the initial start point of the pointer
     * It has to be under the first paragraph on tablet and mobile
     * and then the half of the first line
     */
    setPointerStartPosition = () => {
        if (window.innerWidth < 1280) {
            let firstHistoryTitleWrapper = this.historyTitleWrappers[0];
            let contentPaddingTop = this.getComputedPixel(
                this.content,
                "padding-top"
            );
            let marginTitleWrapperTop = this.getComputedPixel(
                firstHistoryTitleWrapper,
                "margin-top"
            );
            let pointerHeight = this.pointerRect.height;
            this.pointerStartPositionInContent =
                this.firstHistoryTextRect.height +
                contentPaddingTop +
                marginTitleWrapperTop / 2 -
                pointerHeight / 2;
            this.pointer.style.top = this.pointerStartPositionInContent + "px";
        } else {
            let firstTitleWrapperRect = this.historyTitleWrappersRects[0];

            let offset = firstTitleWrapperRect.top - this.contentRect.top;

            this.pointerStartPositionInContent =
                offset + firstTitleWrapperRect.height + 50;
            this.pointer.style.top = this.pointerStartPositionInContent + "px";

            let titleWrapperWidth = 94;
            let pointerHalfWidth = this.pointerRect.width / 2;
            this.pointer.style.right =
                titleWrapperWidth - pointerHalfWidth + "px";
        }
    };

    /**
     * This function calculates the new position of the
     * pointer while the user is scrolling
     *
     */
    handlePointerPosition = () => {
        let offset = this.contentRect.top - this.bodyRect.top;

        // The position in the middle of the screen
        // The current scrolling y + the half of the screen
        // minus the offset of the content to the body (the y position of the content in the whole website), because the
        // pointers top position is related to the content container, not the
        // top of the body
        // and then the pointer is in the middle, but the pointers y starts in the middle
        // and than the height comes on top, so we have to divide the height by two and
        // substract it from the whole
        let pointerMiddleOfScreenPosition =
            window.scrollY +
            window.innerHeight / 2 -
            offset -
            this.pointerRect.height / 2;

        this.pointer.style.top = pointerMiddleOfScreenPosition + "px";

        // If the scrolling is not far enough to start to move the pointer
        if (
            pointerMiddleOfScreenPosition <= this.pointerStartPositionInContent
        ) {
            this.pointer.style.top = this.pointerStartPositionInContent + "px";
        }

        // If the scrolling is to far already so dont move the pointer anymore
        if (
            window.scrollY + window.innerHeight / 2 >=
            offset + this.contentRect.height
        ) {
            let endPos = this.contentRect.height - this.pointerRect.height / 2;
            this.pointer.style.top = endPos + "px";
        }

        this.checkIfInterferingTitleWrapper();
    };

    /**
     * This function checks if the pointer is below a history title and marks it
     * with a white background and a black text color
     * Otherwise the upper changes getting reset
     */
    checkIfInterferingTitleWrapper = () => {
        this.historyTitleWrappersRects.forEach((rect, index) => {
            let wrapper = this.historyTitleWrappers[index];

            let middleOfScreen = window.scrollY + window.innerHeight / 2;

            let contentOffsetInBody = this.contentRect.top - this.bodyRect.top;
            let rectOffsetInContent = rect.top - this.contentRect.top;

            let rectOffsetInBody = rectOffsetInContent + contentOffsetInBody;
            let rectOffsetInBodyPlusHeight = rectOffsetInBody + rect.height / 2;

            if (rectOffsetInBodyPlusHeight <= middleOfScreen) {
                wrapper.style.backgroundColor = "white";
                wrapper.style.color = "black";
            } else {
                wrapper.style.backgroundColor = "";
                wrapper.style.color = "";
            }
        });
    };
}
