import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default class ImageGallery {
    constructor(mediaGalleryButtonClassName) {
        this.mediaGalleryButtonClassName = mediaGalleryButtonClassName;
        this.mediaButtons = document.querySelectorAll(
            this.mediaGalleryButtonClassName
        );
        this.closeButtons = document.querySelectorAll(".image-gallery__close");
        this.events();
    }

    events = () => {
        this.mediaButtons.forEach((mediaButton) =>
            mediaButton.addEventListener("click", this.openImageGallery)
        );
        this.closeButtons.forEach((button) =>
            button.addEventListener("click", (e) => {
                let imageGallery = e.target.parentElement.parentElement;
                imageGallery.classList.remove("image-gallery--visible");
                document.body.style.overflow = "";
            })
        );
    };

    initSwipers = () => {
        let prefix = "." + this.imageGalleryClass + " ";
        this.bottomSwiper = new Swiper(prefix + ".bottom-swiper", {
            slidesPerView: 3,
            spaceBetween: 20,

            breakpoints: {
                824: {
                    slidesPerView: 5,
                    centerInsufficientSlides: true,
                },
            },
        });

        this.topSwiper = new Swiper(prefix + ".top-swiper", {
            autoHeight: true,
            thumbs: {
                swiper: this.bottomSwiper,
            },
            navigation: {
                nextEl: prefix + ".next-btn",
                prevEl: prefix + ".prev-btn",
            },
        });

        this.topSwiper.on("realIndexChange", (swiper) => {
            if (swiper.activeIndex == swiper.slides.length - 1) {
                this.imageGalleryNextBtn.classList.add("next-btn--disabled");
            } else if (swiper.activeIndex == 0) {
                this.imageGalleryPrevBtn.classList.add("prev-btn--disabled");
            } else {
                this.imageGalleryNextBtn.classList.remove("next-btn--disabled");
                this.imageGalleryPrevBtn.classList.remove("prev-btn--disabled");
            }
        });
    };

    openImageGallery = (e) => {
        let button =
            e.target.nodeName != "BUTTON" ? e.target.parentNode : e.target;

        if (!button.hasAttribute("data-image-gallery-class")) return;

        document.body.style.overflow = "hidden";

        this.imageGalleryClass = button.dataset.imageGalleryClass;
        this.imageGallery = document.querySelector(
            "." + this.imageGalleryClass
        );

        this.imageGallery.addEventListener("click", (e) => {
            if (e.target == this.imageGallery) {
                this.imageGallery.classList.remove("image-gallery--visible");
                document.body.style.overflow = "";
            }
        });

        this.spinner = this.imageGallery.querySelector(".lds-roller");
        this.imageGalleryInnerWrapper = this.imageGallery.querySelector(
            ".image-gallery__inner-wrapper"
        );
        this.imageGalleryNextBtn = this.imageGallery.querySelector(".next-btn");
        this.imageGalleryPrevBtn = this.imageGallery.querySelector(".prev-btn");
        this.topSwiperWrapper = this.imageGallery.querySelector(
            ".top-swiper .swiper-wrapper"
        );
        this.bottomSwiperWrapper = this.imageGallery.querySelector(
            ".bottom-swiper .swiper-wrapper"
        );

        if (!this.imageGallery.hasAttribute("data-loaded")) {
            let images = this.getImages();

            let imageElementsTopSwiper = this.createImageElements(images);
            let imageElementsBottomSwiper = this.createImageElements(images);

            let slidesTopSwiper = this.createSlides(imageElementsTopSwiper);
            let slidesBottomSwiper = this.createSlides(
                imageElementsBottomSwiper
            );

            this.topSwiperWrapper.innerHTML = "";
            this.bottomSwiperWrapper.innerHTML = "";

            this.insertSlides(slidesTopSwiper, this.topSwiperWrapper);
            this.insertSlides(slidesBottomSwiper, this.bottomSwiperWrapper);

            this.imageGallery.setAttribute("data-loaded", "true");

            this.imageGallery.classList.add("image-gallery--visible");

            this.initSwipers();

            this.removeSpinnerIfLoaded(imageElementsTopSwiper);
        } else {
            this.imageGallery.classList.add("image-gallery--visible");
        }
    };

    removeSpinnerIfLoaded = (imageElements) => {
        let count = 0;
        imageElements.forEach((element) =>
            element.addEventListener("load", () => {
                count = count + 1;
                if (count === imageElements.length) {
                    this.spinner.remove();
                    this.imageGalleryInnerWrapper.classList.remove(
                        "image-gallery__inner-wrapper--hidden"
                    );
                }
            })
        );
    };

    getImages = () => {
        let imageDivs = this.imageGallery.querySelectorAll(
            ".top-swiper div[data-image]"
        );
        let imageSources = [];
        imageDivs.forEach((div) => {
            imageSources.push(div.dataset.image);
        });
        return imageSources;
    };

    createImageElements = (images) => {
        let imageElements = [];
        images.forEach((image) => {
            let imgElement = document.createElement("img");
            imgElement.src = image;
            imageElements.push(imgElement);
        });
        return imageElements;
    };

    createSlides = (imageElements) => {
        let slides = [];
        imageElements.forEach((imageElement) => {
            let slide = document.createElement("div");
            slide.classList.add("swiper-slide");
            slide.appendChild(imageElement);
            slides.push(slide);
        });
        return slides;
    };

    insertSlides = (slides, wrapper) => {
        slides.forEach((slide) => wrapper.appendChild(slide));
    };
}
