export default class VideoPlayer {
    /**
     *
     * @param {string} class
     */
    constructor(videoButtonClass) {
        this.videoButtonClass = videoButtonClass;
        this.getNeededElements();
        if (!this.neededElementsExist()) return;
        this.events();
    }

    /**
     * Tries to get all elements from the dom and saves them to this
     * object
     */
    getNeededElements = () => {
        this.videoPlayer = document.querySelector(".video-player");
        if (this.videoPlayer == null) return;

        this.videoPlayerNoVideoCloseButton = this.videoPlayer.querySelector(
            ".video-player__no-video-close"
        );
        this.sectionHeader = this.videoPlayer.querySelector(".section-header");
        this.videoPlayerCloseButton = this.videoPlayer.querySelector(
            ".video-player__close"
        );
        this.videoPlayerVideo = this.videoPlayer.querySelector(
            ".video-player__video"
        );
        this.videoButtons = document.querySelectorAll(this.videoButtonClass);
    };

    /**
     * Returns true if all needed elments could be found otherwise false
     *
     * @return {boolean} true if all elements are found otherwise false
     */
    neededElementsExist = () => {
        return (
            this.videoPlayer != null &&
            this.videoPlayerNoVideoCloseButton != null &&
            this.sectionHeader != null &&
            this.videoPlayerCloseButton != null &&
            this.videoPlayerVideo != null &&
            this.videoButtons.length > 0
        );
    };

    /**
     * Creates all needed Event Handlers
     */
    events = () => {
        // Video Container
        this.videoPlayerCloseButton.addEventListener(
            "click",
            this.closeVideoPlayer
        );

        this.videoPlayerNoVideoCloseButton.addEventListener(
            "click",
            this.closeVideoPlayer
        );

        // Video Buttons
        this.videoButtons.forEach((videoButton) => {
            videoButton.addEventListener("click", () => {
                if (videoButton.getAttribute("videoSrc") != null) {
                    this.openVideoPlayer(videoButton.dataset.video ?? "");
                } else {
                    this.videoNotAvailable();
                }
            });
        });
    };

    openVideoPlayer = (videoSrc) => {
        this.videoPlayer.classList.add("video-player--visible");
        this.videoPlayerVideo.src = videoSrc ?? "";
        document.body.style.overflow = "hidden";
    };

    videoNotAvailable = () => {
        this.videoPlayer.classList.add("video-player--visible");
        this.videoPlayer.classList.add("video-player--no-video");
        this.sectionHeader.classList.remove("section-header--hidden");
    };

    closeVideoPlayer = (e) => {
        e.stopPropagation();
        this.videoPlayer.classList.remove("video-player--visible");
        this.videoPlayer.src = "";
        document.body.style.overflow = "auto";
    };
}
