export default class Team {
    constructor() {
        this.getNeededElements();
        if (!this.neededElementsExist()) return;
        this.events();
    }

    /**
     * Get all needed HTML Elements
     *
     */
    getNeededElements = () => {
        this.teamMiddleImage = document.querySelector(
            ".team-section__header-img-middle"
        );
        this.teamInnerImages = document.querySelectorAll(
            ".team-section__header-img-inner"
        );
    };

    /**
     * Check if all needed html elements could be found
     *
     * @returns boolean
     */
    neededElementsExist = () => {
        return this.teamMiddleImage != null && this.teamInnerImages != null;
    };

    /**
     * Register Event Handler
     *
     */
    events = () => {
        // If the content is fully loaded
        // Remove after 1600ms all animation classes
        // Then register event handler for the window scroll event
        // Rotate the images while the user is scrolling
        window.addEventListener("DOMContentLoaded", () => {
            setTimeout(() => {
                this.removeAnimationClasses();

                window.addEventListener("scroll", () => {
                    this.transformElement(this.teamMiddleImage);
                    this.teamInnerImages.forEach((image) => {
                        this.transformElement(image);
                    });
                });

                this.startPageYOffset = window.pageYOffset / 2;
            }, 1600);
        });
    };

    /**
     * Removes animation classes from the imgs
     *
     */
    removeAnimationClasses = () => {
        this.teamMiddleImage.classList.remove(
            "team-section__header-img--animate-inner"
        );

        this.teamInnerImages.forEach((image) => {
            image.classList.remove("team-section__header-img--animate-inner");
        });
    };

    /**
     * Transforms the element according to the pageYOffset
     *
     * @param {HTMLElement} element
     */
    transformElement = (element) => {
        // startPageYOffset saves the y position of the user.
        // Then we substracting the old y position from the current y position.
        // Otherwise the rotation would for example start with 20, this would
        // make the animation look weird if it jumps from 0 to 20
        // By this technique the rotation starts from 0 and goes up and down
        let yOffset = (window.pageYOffset / 2 - this.startPageYOffset) * -1;
        element.style.transform = "rotate(" + yOffset + "deg)";
    };
}
