export default class Contact {
    constructor() {
        this.getNeededElements();
        if (!this.neededElementsExist()) return;
        this.events();
    }

    /**
     * Tries to get all elements from the dom and saves them to this
     * object
     */
    getNeededElements = () => {
        this.inputBorderWrappers = document.querySelectorAll(
            ".contact-section__input-border-wrapper"
        );
        this.customerTypeWrapper = document.querySelector(
            ".contact-section__customer-type-wrapper"
        );
        this.customerTypeInput = document.querySelector(
            '.contact-section input[name="customer-type"]'
        );
        this.customerIdInput = document.querySelector(
            '.contact-section input[name="customer-id"]'
        );
    };

    /**
     * Returns true if all needed elments could be found otherwise false
     *
     * @return {boolean} true if all elements are found otherwise false
     */
    neededElementsExist = () => {
        return (
            this.inputBorderWrappers != null &&
            this.customerTypeWrapper != null &&
            this.customerTypeInput != null &&
            this.customerIdInput != null
        );
    };

    /**
     * Creates all needed Event Handlers
     */
    events = () => {
        window.addEventListener(
            "DOMContentLoaded",
            this.addInputBorderWrappersClass
        );
        this.customerTypeInput.addEventListener(
            "change",
            this.handleCustomerTypeSelection
        );
    };

    /**
     * Adds a modifier class to all inputBorderWrapper elements to
     * trigger the border animation
     */
    addInputBorderWrappersClass = () => {
        this.inputBorderWrappers.forEach((inputBorderWrapper) =>
            inputBorderWrapper.classList.add(
                "contact-section__input-border-wrapper--border-visible"
            )
        );
    };

    /**
     * Gets triggered when the customer type selection changes
     * If the new customer type selection is existing customer
     * it will display the customer-id text field
     *
     * @param {Event} e
     */
    handleCustomerTypeSelection = (e) => {
        if (e.target.value === "existing-customer") {
            this.customerTypeWrapper.classList.add(
                "contact-section__customer-type-wrapper--existing-customer"
            );
        } else {
            this.customerTypeWrapper.classList.remove(
                "contact-section__customer-type-wrapper--existing-customer"
            );
            this.customerIdInput.value = "";
        }
    };
}
