export default class AirConditioning {
    constructor() {
        this.getNeededElements();
        if (!this.neededElementsExist()) return;
        this.addObserver();
        this.events();
        this.fixCelsiusLabelWidth();
    }

    /**
     * Tries to get all elements from the dom and saves them to this
     * object
     */
    getNeededElements = () => {
        this.airConditioningSection = document.querySelector(
            ".air-conditioning-section"
        );
        this.airConditioningImg = document.querySelector(
            ".air-conditioning-section__img-wrapper"
        );
        this.airConditioningImgInner = document.querySelector(
            ".air-conditioning-section__img-inner"
        );
        this.airConditioningSectionCelsius = document.querySelector(
            ".air-conditioning-section__celsius"
        );
    };

    /**
     * Returns true if all needed elments could be found otherwise false
     *
     * @return {boolean} true if all elements are found otherwise false
     */
    neededElementsExist = () => {
        return (
            this.airConditioningSection != null &&
            this.airConditioningImg != null &&
            this.airConditioningImgInner != null &&
            this.airConditioningSectionCelsius != null
        );
    };

    /**
     * Initialises an Intersection Observer which is needed to detect
     * when to start the thermometer image animation
     */
    addObserver = () => {
        const observer = new IntersectionObserver(
            (entries) => {
                let imgEntry = entries[0];
                if (imgEntry.isIntersecting) {
                    imgEntry.target.classList.add(
                        "air-conditioning-section__img-wrapper--visible"
                    );
                    observer.unobserve(imgEntry.target);
                }
            },
            {
                threshold: 0.8,
            }
        );

        observer.observe(this.airConditioningImg);
    };

    /**
     * Creates all needed Event Handlers
     *
     */
    events = () => {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.fixCelsiusLabelWidth);
    };

    /**
     * This function acts as the scroll event handler.
     * It's purpose is to move the red triangle image while the user is scrolling
     * and to change the scale of the house img in the background
     *
     */
    handleScroll = () => {
        let startPosition = 0;

        // Desktop
        if (this.isDesktop()) {
            startPosition = this.airConditioningSection.offsetTop - 300;
        }
        // Tablet
        else if (this.isTablet()) {
            startPosition = this.airConditioningSection.offsetTop - 300;
        }
        // Mobile
        else if (this.isMobile()) {
            startPosition = this.airConditioningSection.offsetTop - 150;
        }

        let currentPos = scrollY - startPosition;
        let range = 300;

        //clip-path: polygon(0 53%, 100% 43%, 100% 100%, 0% 100%);

        let topLeftStart = 13;
        let topRightStart = 3;
        let diff = this.isDesktop() || this.isTablet() ? 30 : 40;

        let textStart = this.isDesktop() ? -247 : -168;
        let textDiff = this.isDesktop() ? 174 : 156;

        if (
            window.scrollY >= startPosition &&
            window.scrollY < startPosition + range
        ) {
            // Img Inner img manipulation
            let oneStep = diff / range;
            let reached = oneStep * currentPos;
            this.airConditioningImgInner.style.clipPath =
                "polygon(0 " +
                (topLeftStart + reached) +
                "%, 100% " +
                (topRightStart + reached) +
                "%, 100% 100%, 0% 100%)";

            // Text celsius manipulation
            let textOneStep = textDiff / range;
            let textReached = textOneStep * currentPos;
            this.airConditioningSectionCelsius.style.transform =
                "translateY(" + (textStart + textReached) + "px)";

            let index = Math.ceil(currentPos / (range / 15));
            this.airConditioningSectionCelsius.textContent = 35 - index + "°C";
        } else if (window.scrollY > startPosition + range) {
            this.airConditioningSectionCelsius.style.transform =
                "translateY(" + (textStart + textDiff) + "px)";

            this.airConditioningSectionCelsius.textContent = "20°C";

            this.airConditioningImgInner.style.clipPath =
                "polygon(0 " +
                (topLeftStart + diff) +
                "%, 100% " +
                (topRightStart + diff) +
                "%, 100% 100%, 0% 100%)";
        } else if (window.scrollY < startPosition) {
            this.airConditioningSectionCelsius.style.transform =
                "translateY(" + textStart + "px)";

            this.airConditioningSectionCelsius.textContent = "35°C";

            this.airConditioningImgInner.style.clipPath =
                "polygon(0 " +
                topLeftStart +
                "%, 100% " +
                topRightStart +
                "%, 100% 100%, 0% 100%)";
        }
    };

    isDesktop = () => {
        return window.innerWidth >= 1280;
    };

    isTablet = () => {
        return window.innerWidth < 1280 && window.innerWidth >= 824;
    };

    isMobile = () => {
        return window.innerWidth < 824;
    };

    /**
     * This function handles the resize event of the window
     * We are using this handler only to change the width of the
     * celsius label.
     * The label has to be fixed in his width because it's text
     * ist changing a lot and this causes the also the thermometer image
     * to shake weird
     */
    fixCelsiusLabelWidth = () => {
        if (window.innerWidth >= 1280) {
            this.airConditioningSectionCelsius.style.width = "138px";
        } else if (window.innerWidth < 824) {
            this.airConditioningSectionCelsius.style.width = "73px";
        }
    };
}
