import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import CustomSlider from "./CustomSlider";

export default class Sanitary {
    constructor() {
        this.initContentSwiper();
        this.addContentSwiperEventHanlder();

        this.initTitleSwiper();
        this.addTitleSwiperEventHanlder();

        this.initTitleSliderDesktop();
        this.addTitleSliderDesktopEventHanlder();
    }

    initTitleSwiper = () => {
        this.titleSwiper = new Swiper(".sanitary-section__title-swiper", {
            direction: "horizontal",
            loop: false,

            navigation: {
                nextEl: ".sanitary-section .swiper-button-next",
                prevEl: ".sanitary-section .swiper-button-prev",
            },
        });
    };

    addTitleSwiperEventHanlder = () => {
        this.titleSwiper.on("slideChange", () => {
            this.contentSwiper.slideTo(this.titleSwiper.activeIndex);
        });
    };

    initContentSwiper = () => {
        this.contentSwiper = new Swiper(".sanitary-section__content-swiper", {
            direction: "horizontal",
            loop: false,

            pagination: {
                el: ".sanitary-section__pagination",
                clickable: true,
            },
        });
        this.contentSwiper.on("realIndexChange", (swiper) => {
            let prevSlide = swiper.slides[swiper.previousIndex];
            prevSlide.classList.add("swiper-slide--hide-img");

            let nextSlide = swiper.slides[swiper.activeIndex];
            nextSlide.classList.remove("swiper-slide--hide-img");
        });
    };

    addContentSwiperEventHanlder = () => {
        this.contentSwiper.on("beforeTransitionStart", () => {
            if (this.ignoreEvent) {
                this.ignoreEvent = false;
                return;
            }

            // This should only get triggered if the
            // Slider for the desktop is not visible
            if (!this.titleSliderDesktop.isVisible()) {
                this.titleSwiper.slideTo(this.contentSwiper.activeIndex);
            } else {
                // Should only run if the slider is visible
                if (
                    this.contentSwiper.previousIndex <
                    this.contentSwiper.activeIndex
                ) {
                    this.titleSliderDesktop.nextSlide();
                } else {
                    this.titleSliderDesktop.prevSlide();
                }
            }
        });
    };

    initTitleSliderDesktop = () => {
        this.titleSliderDesktop = new CustomSlider(".custom-slider");
    };

    addTitleSliderDesktopEventHanlder = () => {
        this.titleSliderDesktop.listenOnNextSlideChange(() => {
            this.ignoreEvent = true;
            this.contentSwiper.slideNext();
        });

        this.titleSliderDesktop.listenOnPrevSlideChange(() => {
            this.ignoreEvent = true;
            this.contentSwiper.slidePrev();
        });
    };
}
