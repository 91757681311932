export default class CustomSlider {
    constructor(sliderClass) {
        this.sliderClass = sliderClass;
        this.getNeededElements();
        if (!this.neededElementsExist()) return;
        this.currentActive = 0;
        this.events();
    }

    listenOnNextSlideChange = (callback) => {
        this.nextSlideChangeCallback = callback;
    };

    listenOnPrevSlideChange = (callback) => {
        this.prevSlideChangeCallback = callback;
    };

    isVisible = () => {
        return window.getComputedStyle(this.slider, null).display === "block";
    };

    /**
     * Tries to get all elements from the dom and saves them to this
     * object
     */
    getNeededElements = () => {
        if (this.sliderClass == null && this.sliderClass == "") return;
        this.slider = document.querySelector(this.sliderClass);
        if (this.slider == null) return;
        this.wrapper = this.slider.querySelector(".custom-slider__wrapper");
        this.nextEl = this.slider.querySelector(
            ".custom-slider__button-wrapper-next"
        );
        this.prevEl = this.slider.querySelector(
            ".custom-slider__button-wrapper-prev"
        );
        this.headings = this.slider.querySelectorAll(".custom-slider__heading");
    };

    /**
     * Returns true if all needed elments could be found otherwise false
     *
     * @return {boolean} true if all elements are found otherwise false
     */
    neededElementsExist = () => {
        return (
            this.slider != null &&
            this.nextEl != null &&
            this.prevEl != null &&
            (this.headings != null) & (this.headings.length > 0)
        );
    };

    /**
     * Register all needed event handler
     */
    events = () => {
        this.nextEl.addEventListener("click", () => this.nextSlide(true));
        this.prevEl.addEventListener("click", () => this.prevSlide(true));

        // The heading at pos 1 is the one which represents the heading
        // of the next slide
        // The user can click it to switch to the next slide
        this.headings[1].addEventListener("click", this.nextSlide);
        this.headings[1].style.cursor = "pointer";
    };

    /**
     * Calculate the distance to move the heading
     * from right to left
     *
     * @param {HTMLElement} heading
     * @returns number
     */
    getDistanceToLeft = (heading) => {
        return (
            heading.getBoundingClientRect().left -
            this.wrapper.getBoundingClientRect().left
        );
    };

    /**
     * This means the current active heading / slide
     * gets invisible by settings the display to none
     *
     * This happends when the user pressed the next button
     * or the next heading
     *
     * @param {HTMLElement} heading
     */
    setFromActiveToHide = (heading) => {
        heading.style.display = "none";
    };

    /**
     * This will translate the next heading / slide from the right side
     * to the left side and removes the text-stroke and adds a color to
     * the now active heading.
     * It also removes the event listener that will show the next slide
     *
     * This happends when the user pressed the next button
     * or the next heading
     *
     * @param HTMLElement heading
     */
    setFromNextToActive = (heading) => {
        let distance = this.getDistanceToLeft(heading);
        heading.style.transform =
            "translateY(-50%) translateX(-" + distance + "px)";
        heading.style.cursor = "auto";
        heading.style["-webkit-text-stroke"] = "0px";
        heading.style.color = "black";
        heading.removeEventListener("click", this.nextSlide);
    };

    /**
     * The slide that is currently invisible on the right side.
     * the opacity of this heading will be set to 1 and also
     * the display will be get changed from none to block,
     * Display none is set in the css file for all headings
     * which are not the first or the second one at the first page load
     * This element gets also the cursor pointer attribute and
     * gets added a event listener that allows the user to see the
     * next heading / slide
     *
     * This happends when the user pressed the next button
     * or the next heading
     *
     * @param HTMLElement heading
     */
    setFromHideToNext = (heading) => {
        heading.style.display = "block";
        heading.style.opacity = 1;
        heading.style.cursor = "pointer";
        heading.addEventListener("click", this.nextSlide);
    };

    /**
     * The current active slide gets translated back to its position
     * on the right side, by just settings translateX back to zero.
     * It will now represent the next heading / slide.
     *
     * This happends when the user pressed the prev button
     *
     * @param HTMLElement heading
     */
    setActiveToNextSlide = (heading) => {
        heading.style.transform = "translateY(-50%) translateX(0px)";
        heading.style.cursor = "pointer";
        heading.style["-webkit-text-stroke"] = "1px black";
        heading.style.color = "white";
        heading.addEventListener("click", this.nextSlide);
    };

    /**
     * The heading on the left side which is currently invisible
     * because of display => block, gets visible by settings the opacity back to 1
     *
     * This happends when the user pressed the prev button
     *
     * @param HTMLElement heading
     */
    setHideToActive = (heading) => {
        heading.style.display = "block";
    };

    /**
     * The heading / slide which is visible on the right side, gets
     * just invisible by setting the opacity to zero
     *
     * This happends when the user pressed the prev button
     *
     * @param HTMLElement heading
     */
    setNextToHide = (heading) => {
        heading.style.opacity = "0";
    };

    /**
     * Gets triggered when the next button gets clicked
     * or the heading which represents the next slide
     */
    nextSlide = (byInteraction) => {
        if (this.currentActive == this.headings.length - 1) return;

        // Calls callback only if prevSlide got not called directly
        // Calls only if prevSlide got called by click event handler
        if (byInteraction) this.nextSlideChangeCallback();

        if (this.currentActive == 0) {
            this.wrapper.style.marginLeft = "76px";
            this.prevEl.style.display = "block";
        }

        this.slider.dispatchEvent(new Event("nextSlidePressed"));

        this.setFromActiveToHide(this.headings[this.currentActive]);

        this.currentActive += 1;

        this.setFromNextToActive(this.headings[this.currentActive]);

        if (this.currentActive + 1 < this.headings.length) {
            this.setFromHideToNext(this.headings[this.currentActive + 1]);
        }

        if (this.currentActive == this.headings.length - 1) {
            this.nextEl.style.display = "none";
        }
    };

    /**
     * Gets triggered when the prev button gets clicked
     */
    prevSlide = (byInteraction) => {
        if (this.currentActive === 0) return;

        // Calls callback only if prevSlide got not called directly
        // Calls only if prevSlide got called by click event handler
        if (byInteraction) this.prevSlideChangeCallback();

        if (this.currentActive == this.headings.length - 1) {
            this.nextEl.style.display = "block";
        }

        if (this.currentActive == 1) {
            this.wrapper.style.marginLeft = "39px";
        }

        this.slider.dispatchEvent(new Event("prevSlidePressed"));

        this.setActiveToNextSlide(this.headings[this.currentActive]);

        if (this.currentActive + 1 < this.headings.length) {
            this.headings[this.currentActive + 1].style.opacity = "0";
        }

        this.currentActive -= 1;
        this.setHideToActive(this.headings[this.currentActive]);

        if (this.currentActive == 0) {
            this.prevEl.style.display = "none";
        }
    };
}
