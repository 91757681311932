import "./bootstrap";
import Header from "./modules/Header";
import Footer from "./modules/Footer";
import Radiator from "./modules/Radiator";
import Sanitary from "./modules/Sanitary";
import AirConditioning from "./modules/AirConditioning";
import Ventilation from "./modules/Ventilation";
import Support from "./modules/Support";
import Contact from "./modules/Contact";
import Select from "./modules/Select";
import SectionHeader from "./modules/SectionHeader";
import VideoPlayer from "./modules/VideoPlayer";
import ImageGallery from "./modules/ImageGallery";
import Funding from "./modules/Funding";
import Team from "./modules/Team";
import History from "./modules/History";
import BannerHandler from "./modules/BannerHandler";

const header = new Header();
const footer = new Footer();
const radiator = new Radiator();
const sanitary = new Sanitary();
const airConditioning = new AirConditioning();
const ventilation = new Ventilation();
const support = new Support();
const contact = new Contact();
const sectionHeader = new SectionHeader();
const funding = new Funding();
const team = new Team();
const history = new History();
const bannerHandler = new BannerHandler();

const videoPlayer = new VideoPlayer(".media-button--video");
const imageGallery = new ImageGallery(".media-button--gallery");

// Custom Select Contact Form
const selectCustomerType = new Select(".contact-section__select-customer-type");
const selectContactReason = new Select(
    ".contact-section__select-contact-reason"
);
