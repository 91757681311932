export default class BannerHandler {
    constructor() {
        this.getNeededElements();
        if (!this.neededElementsExist()) return;
        this.events();
    }

    /**
     * Tries to get all elements from the dom and saves them to this
     * object
     */
    getNeededElements = () => {
        this.bannerContainer = document.querySelector(".banner-container");
        this.banner = document.querySelector(".banner");
        if (!this.banner) return;
        this.proceedBtn = document.querySelector(".banner__proceed-button");
    };

    /**
     * Returns true if all needed elments could be found otherwise false
     *
     * @return {boolean} true if all elements are found otherwise false
     */
    neededElementsExist = () => {
        return (
            this.bannerContainer != null &&
            this.banner != null &&
            this.proceedBtn != null
        );
    };

    /**
     * Register Event Handler
     */
    events = () => {
        this.proceedBtn.addEventListener("click", this.handleProceedBtnClick);
    };

    /**
     * This function calls the current route with a get parameter to let the appliation know, that
     * it should set a cookie in this client so the banner will not show up again
     *
     * @param { Event } e
     */
    handleProceedBtnClick = async (e) => {
        e.preventDefault();
        let url = this.proceedBtn.getAttribute("href");
        const params = new URLSearchParams();
        params.append("banner-seen", "");

        await fetch(`${url}?${params}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        this.bannerContainer.remove();
    };
}
