export default class SectionHeader {
    constructor() {
        this.getNeededElements();
        if (!this.neededElementsExist()) return;
        this.initIntersectionObserver();
    }

    /**
     * Tries to get all elements from the dom and saves them to this
     * object
     */
    getNeededElements = () => {
        this.sectionHeaders = document.querySelectorAll(
            ".section-header--animate"
        );
    };

    /**
     * Returns true if all needed elments could be found otherwise false
     *
     * @return {boolean} true if all elements are found otherwise false
     */
    neededElementsExist = () => {
        return this.sectionHeaders.length > 0;
    };

    initIntersectionObserver = () => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.remove(
                            "section-header--animate"
                        );
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 1,
            }
        );

        this.sectionHeaders.forEach((header) => {
            observer.observe(header);
        });
    };
}
