export default class Funding {
    constructor() {
        this.getNeededElements();
        if (!this.neededElementsExist()) return;
        this.initIntersectionObserver();
        window.addEventListener("scroll", this.handleScroll);
    }

    /**
     * Tries to get all elements from the dom and saves them to this
     * object
     */
    getNeededElements = () => {
        this.imageWrapper = document.querySelector(
            ".funding-section__img-wrapper"
        );
        this.redTriangleBigImg = document.querySelector(
            ".funding-section__red-triangle-big-img"
        );
        this.houseImg = document.querySelector(".funding-section__house-img");
        this.fundingSection = document.querySelector(".funding-section");
    };

    /**
     * Returns true if all needed elments could be found otherwise false
     *
     * @return {boolean} true if all elements are found otherwise false
     */
    neededElementsExist = () => {
        return (
            this.imageWrapper != null &&
            this.redTriangleBigImg != null &&
            this.fundingSection != null
        );
    };

    /**
     * This function acts as the scroll event handler.
     * It's purpose is to move the red triangle image while the user is scrolling
     * and to change the scale of the house img in the background
     *
     */
    handleScroll = () => {
        let startPosition = 0;

        // Desktop
        if (window.innerWidth >= 1280) {
            startPosition = this.fundingSection.offsetTop - 400;
        }
        // Tablet
        else if (window.innerWidth < 1280 && window.innerWidth >= 824) {
            startPosition = this.fundingSection.offsetTop;
        }
        // Mobile
        else if (window.innerWidth < 824) {
            startPosition = this.fundingSection.offsetTop + 50;
        }

        let currentPos = scrollY - startPosition;
        let range = 400;
        let topDiff = 87;
        let topStartValue = 12;
        let scaleStartValue = 0.7;
        let scale = 0.3;

        if (
            window.scrollY >= startPosition &&
            window.scrollY < startPosition + range
        ) {
            // Triangle img manipulation
            let topOneStep = topDiff / range;
            let reached = topOneStep * currentPos;
            this.redTriangleBigImg.style.top = 100 - reached + "%";

            // House img manipulation
            let scaleOneStep = scale / range;
            let scaleReached = scaleOneStep * currentPos + scaleStartValue;
            this.houseImg.style.transform = "translateX(-110px)";
            if (window.innerWidth >= 1280) {
                this.houseImg.style.transform += "scale(" + scaleReached + ")";
            } else {
                this.houseImg.style.transform = "scale(" + scaleReached + ")";
            }
        } else if (window.scrollY > startPosition + range) {
            this.redTriangleBigImg.style.top = topStartValue + "%";
            this.houseImg.style.scale = 1;
        }
    };

    /**
     * Initialize Intersection Observer
     */
    initIntersectionObserver = () => {
        this.observer = new IntersectionObserver(this.observerCallback);
        this.observer.observe(this.imageWrapper);

        // this.triangleObserver = new IntersectionObserver(
        //     this.scrollAnimationCallback,
        //     {
        //         threshold: 0.7,
        //     }
        // );
        // this.triangleObserver.observe(this.imageWrapper);
    };

    /**
     * Intersection Observer Callback Function
     *
     * @param {IntersectionObserverEntry[]} entries
     */
    observerCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.triggerAnimation();
                this.observer.unobserve(this.imageWrapper);
            }
        });
    };

    triggerAnimation = () => {
        this.imageWrapper.classList.add(
            "funding-section__img-wrapper--visible"
        );
    };
}
